import { render, staticRenderFns } from "./CreateAuthor.vue?vue&type=template&id=6ea03581&scoped=true"
import script from "./CreateAuthor.vue?vue&type=script&lang=js"
export * from "./CreateAuthor.vue?vue&type=script&lang=js"
import style0 from "./CreateAuthor.vue?vue&type=style&index=0&id=6ea03581&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ea03581",
  null
  
)

export default component.exports